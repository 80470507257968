<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <div>
      <overlay-alert
        :show-progress="showProgress"
        :process-alert="processAlert"
      />
    </div>
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-credit-card-check
      </v-icon> Subscription Plans
    </h2>
    <br>
    <v-btn
      color="red darken-3"
      @click="backToTimeSlots()"
    >
      Back
    </v-btn>
    <v-alert
      v-if="gotOneInCart"
      text
      prominent
      type="warning"
      color="red"
      border="left"
      class="checkout-alert"
    >
      Please remove the one you saved in your cart before trying a new one.
    </v-alert>
    <v-card
      class="my-base-vcard-style"
    >
      <br>
      <div v-if="isPaymentPlansAvailable">
        <v-card
          class="my-vcard-style"
        >
          <v-container
            class="pa-4 text-center"
          >
            <v-row
              v-if="digiBoardTimeSlotPrices.active === true && digiBoardTimeSlotPrices.prices.length > 0"
              class="fill-height"
              align="center"
              justify="center"
            >
              <template v-for="(price, index) in digiBoardTimeSlotPrices.prices">
                <v-col
                  :key="index"
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <div>
                    <v-card
                      style="border:1px solid #37474F; border-radius:25px"
                      :disabled="!isPlanActive(price.active)"
                      class="price-card"
                    >
                      <v-row
                        class="fill-height flex-column text-h6"
                        align="center"
                        justify="center"
                      >
                        <br>
                        <br>
                        <div>
                          <p class="plan-style">
                            {{ price.name }}
                          </p>
                        </div>
                        <div>
                          <p class="amount-style">
                            <span class="currency-style">{{ price.currency.toUpperCase() }}</span> {{ price.display_price }}
                          </p>
                          <p class="amnt-month-style">
                            Monthly/Screen
                          </p>
                        </div>
                        <div>
                          <p class="amnt-year-style">
                            Total: {{ price.currency.toUpperCase() }} {{ price.amount | amountConverter }} /{{ price.pricing_period | periodCoverter }}
                          </p>
                        </div>
                        <div>
                          <v-btn
                            v-if="isFirstTimeUser"
                            class="btn-style"
                            color="#FF3700"
                            :disabled="gotOneInCart"
                            @click="startFreeTrial(price)"
                          >
                            30 Days Free Trial
                          </v-btn>
                          <v-btn
                            v-else
                            class="btn-style"
                            color="#FF3700"
                            @click="addToCart(price)"
                          >
                            Add to Cart
                          </v-btn>
                        </div>
                        <br>
                        <div>
                          <p class="description-style">
                            <v-icon color="#2E7D32">
                              mdi-check
                            </v-icon>
                            Media Player Software<br><span class="product-name">BannerEx</span>
                          </p>
                        </div>
                        <br>
                      </v-row>
                    </v-card>
                  </div>
                  <div
                    v-if="price.display_description !== null"
                    class="tooltip-style"
                  >
                    <p>
                      <v-icon
                        left
                        color="#2E7D32"
                      >
                        mdi-tag
                      </v-icon>{{ price.display_description }}
                    </p>
                  </div>
                  <div v-else>
                    <br>
                    <br>
                  </div>
                </v-col>
              </template>
            </v-row>
          </v-container>
        </v-card>
      </div>
      <div v-else>
        <p
          class="alert-message-color"
        >
          <br>
          No pricing plans found for this device time slot.
        </p>
      </div>
      <br>
      <centre-spinner
        :loading="loading"
      />
    </v-card>
  </v-container>
</template>

<script>
 import spinner from 'src/views/dashboard/component/SpinnerCentre';
 import OverlayAlert from 'src/views/dashboard/component/OverlayAlert';
 import global from 'src/mixins/global';
 import Constants from 'src/constants';

 export default {
    name: 'ProductPaymentPlans',
    components: {
      'centre-spinner': spinner,
      'overlay-alert': OverlayAlert,
    },
    filters: {
      amountConverter (amount) {
        return amount / 100;
      },
      periodCoverter (period) {
        if (period === 'threeYearly') {
          return '3 Years';
        } else if (period === 'monthly') {
          return 'Month';
        } else if (period === 'sixMonthly') {
          return '6 Months';
        } else if (period === 'quarterly') {
          return '3 Months';
        } else if (period === 'yearly') {
          return 'Year';
        }
      },
    },
    mixins: [global],
    data () {
      return {
        loading: false,
        showProgress: false,
        processAlert: '',
      };
    },
    computed: {
      digiBoardTimeSlotDetails () {
        return this.$store.getters['digiboards/getDigiBoardTimeSlotPrices'];
      },
      digiBoardTimeSlotPrices () {
        if (Object.keys(this.digiBoardTimeSlotDetails).length > 0) {
          const digiBoardTimeSlotPrices = this.digiBoardTimeSlotDetails.product;
          return digiBoardTimeSlotPrices;
        }
        return {};
      },
      isPaymentPlansAvailable () {
        if (this.digiBoardTimeSlotPrices !== undefined && this.digiBoardTimeSlotPrices !== null) {
          return true;
        }
        return false;
      },
      organizationDetails () {
        return this.$store.getters['userprofile/getOrganizationDetails'];
      },
      organizationPurchaseHistory () {
        return this.$store.getters['userprofile/getOrganizationPurchaseHistory'];
      },
      isFirstTimeUser () {
        if (this.organizationPurchaseHistory.length === 0) {
          return true;
        }
        return false;
      },
      productsCart () {
        return this.$store.getters['payments/getProductsCart'];
      },
      gotOneInCart () {
        if (this.isFirstTimeUser === true && this.productsCart.length > 0) {
          return true;
        }
        return false;
      },
    },
    async mounted () {
      await this.fetchOrganizationDetails();
      await this.fetchOrganizationPurchaseHistory();
      this.permissionCheck('read-time-slot-prices') && await this.fetchDigiBoardTimeSlotPrices();
    },
    methods: {
      async fetchOrganizationDetails () {
        this.loading = true;
        await this.$store.dispatch('userprofile/fetchOrganizationDetails');
        this.loading = false;
      },
      async fetchOrganizationPurchaseHistory () {
        this.loading = true;
        await this.$store.dispatch('userprofile/fetchOrganizationPurchaseHistory', {
          organizationId: this.organizationDetails.external_id,
        });
        this.loading = false;
      },
      async fetchDigiBoardTimeSlotPrices () {
        const digiBoardTimeSlotId = this.$route.params.digi_board_time_slot_id;
        this.loading = true;
        await this.$store.dispatch('digiboards/fetchDigiBoardTimeSlotPrices', {
          digiBoardTimeSlotId: digiBoardTimeSlotId,
        });
        this.loading = false;
      },
      isPlanActive (priceStatus) {
        if (priceStatus === true) {
          return true;
        }
        return false;
      },
      startFreeTrial (productPrice) {
        this.$store.dispatch('payments/addProductsToCart', {
          digiBoardDetails: this.digiBoardTimeSlotDetails,
          productPrice: productPrice,
        });
        this.showProgress = true;
        this.processAlert = 'Saving...';
        setTimeout(() => {
          this.$router.push('/payments/products-cart');
        }, 2000);
      },
      addToCart (productPrice) {
        if (this.productsCart.length > 0 && this.productsCart.some(item => (item.digiBoardDetails.id === this.digiBoardTimeSlotDetails.id) && (item.digiBoardDetails.digi_board.external_id === this.digiBoardTimeSlotDetails.digi_board.external_id))) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You already got this slot in your cart. please remove it before selecting a new plan.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        if (this.productsCart.length > 0 && this.productsCart[0].productPrice.pricing_period !== productPrice.pricing_period) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are supposed to save only those slots whose "Pricing Period" same as what you got in your cart.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        if (this.productsCart.length === 20) {
          this.$store.dispatch('alert/onAlert', {
            message: 'Exceeded checkout limit, please purchase or remove any of the carted products before saving a new one.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.showProgress = true;
        this.processAlert = 'Saving...';
        this.$store.dispatch('payments/addProductsToCart', {
          digiBoardDetails: this.digiBoardTimeSlotDetails,
          productPrice: productPrice,
        });
        setTimeout(() => {
          this.showProgress = false;
        }, 2000);
      },
      backToTimeSlots () {
        const digiBoardExternalId = this.$route.params.external_id;
        this.$router.push(`/digi-board/${digiBoardExternalId}/time-slots`);
      },
    },
  };
</script>
<style scoped>
.my-vcard-style {
  margin-right: 30px;
  margin-left: 30px;
  background-color: #ECEFF1;
  border-radius: 25px;
}
.my-base-vcard-style {
  background-color: #CFD8DC !important;
}
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.div-style {
  margin-right: auto;
  margin-left: auto;
}
.plan-style {
  color: #FF3700;
  font-size: 25px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
}
.amount-style {
  color: #37474F;
  font-size: 21px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
}
.amnt-month-style {
  color: #37474F;
  font-size: 15PX;
}
.amnt-year-style {
  color: #37474F;
  font-size: 16PX;
  font-weight: bold;
}
.btn-style {
  margin-left: 15px;
}
.main-div {
  display: flex;
  justify-content: center;
}
.description-style {
  color: #37474F;
  font-size: 16PX;
  font-family: 'Times New Roman', Times, serif;
}
.product-name {
  color: #546E7A;
  font-size: 18PX;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  font-style: italic;
}
.currency-style {
 font-size: 22px;
 font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.tooltip-style {
  color: #2E7D32;
  font-size: 18PX;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  font-style: italic;
}
.checkout-alert {
  font-family: 'Times New Roman', Times, serif;
  color: #C62828;
  font-size: 17px;
  margin-top: 15px;
}
.alert-message-color {
  color: #C62828;
  text-align: center;
}
.price-card {
  -webkit-box-shadow: 0 11px 60px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 11px 60px 0 rgba(0, 0, 0, 0.1);
  padding: 15px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 5px;
}
.price-card:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  -webkit-box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
}
</style>
